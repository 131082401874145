<template>
  <Recuperacao />
</template>

<script>
import Recuperacao from "@/components/users/Recuperacao.vue";

export default {
  components: {
    Recuperacao
  }
};
</script>
